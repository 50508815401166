import { configure, extend } from 'vee-validate';
import { validations as generalValidations } from '@takamol/nawa-library/src/validations';
import i18n from '@/i18n';
import { RULES } from './validationRules';

export const setupValidation = () => {
  configure({
    defaultMessage: (field, values) => i18n.t(
      // eslint-disable-next-line
      `validations.${values._rule_}`, values,
    ),
  });
};

const validations = [
  ...generalValidations,
  {
    name: 'entityBannedWords',
    rule: RULES.bannedWords,
  },
  {
    name: 'minLengthCustom',
    rule: RULES.minLengthCustom,
  },
  {
    name: 'commentRequired',
    rule: RULES.required,
  },
  {
    name: 'areaOfActivity',
    rule: RULES.validate,
  },
  {
    name: 'noEmptyCitiesInArea',
    rule: RULES.notTrue,
  },
];

export const setupValidationRules = () => {
  validations.forEach((validation) => {
    extend(
      validation.name,
      validation.rule,
    );
  });
};
