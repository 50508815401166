import { getField, updateField } from 'vuex-map-fields';
import { normalizeEntity } from '@/services/entity.service';
import { EntityCardsTypes, UnitTypes, EntityTypes } from '@/constants/enums';
import EntitiesListApi from '@/api/entities-list.api';
import { bulkMembersCount } from '@/services/user-requests.service';

const handlePublicEntities = (data) => data.map((item) => {
  const entity = normalizeEntity(item);
  entity.unifiedNumber700 = item.unifiedNumber700;
  entity.classification = item.classificationDetails?.mainClassificationArTitle;
  entity.firstClassification = item.classificationDetails?.firstSubClassificationArTitle;
  entity.secondClassification = item.classificationDetails?.secondSubClassificationArDescription;
  entity.supervisory = item.classificationDetails?.departmentArTitle;
  entity.goals = item.goals;
  entity.activities = item.activities;
  entity.status = item.status;
  return entity;
});

function makeBulkMemberCountPayload(entities) {
  return entities.map((entity) => ({
    unitId: Number(entity.id),
    unitType: UnitTypes.Entity,
  }));
}

function findMembersCount(entities, searchUnit) {
  if (!entities) {
    return null;
  }
  return entities.find(({ unitId }) => unitId === Number(searchUnit.id))?.membersCount;
}

const getFiltersPattern = () => ({
  entityUid: '',
  region: '',
  city: [],
  name: '',
  entityType: '',
  secondSubClassificationIds: '',
  goalId: '',
  activityId: '',
  hijriDateFrom: '',
  hijriDateTo: '',
  classification: '',
  firstClassification: '',
  mainClassificationId: '',
  status: '',
  unifiedNumber700: '',
});

const mainState = {
  entities: [],
  branches: [],
  entitiesByMember: [],
  entitiesLoaded: false,
  landingEntities: [],
  entityDetails: null,
  entityFilters: getFiltersPattern(),
  loading: false,
  filterType: EntityCardsTypes.EntityOwner,
  activeEntityId: '',
  entitiesOwnerMeta: {},
  entitiesMemberMeta: {},
  entitiesBranchMeta: {},
  pages: {
    entitiesOwnerPage: 1,
    entitiesMemberPage: 1,
    entitiesBranchPage: 1,
  },
};

const mainGetters = {
  getField,
  isLoading: (store) => store.loading,
  filterType: (store) => store.filterType,
  getLandingEntities: (store) => store.landingEntities,
  activeEntityId: (store) => store.activeEntityId,
  getEntityFilters: (store) => store.entityFilters,
};

const mainMutations = {
  updateField,
  setLandingEntities: (store, landingEntities) => {
    store.landingEntities = landingEntities;
  },
  setLoading: (store, value) => {
    store.loading = value;
  },
  resetEntityFilters: (store) => {
    store.entityFilters = getFiltersPattern();
  },
  resetEntityClassificationsFilters: (store) => {
    store.entityFilters.secondSubClassificationIds = '';
    store.entityFilters.mainClassificationId = '';
    store.entityFilters.firstClassification = '';
    store.entityFilters.classification = '';
  },
};

const mainActions = {
  fetchLandingEstablishedEntities: async ({ commit, getters, rootGetters }) => {
    const filtersBy = getters.getEntityFilters;
    const payload = {
      page: Number(rootGetters['landingEntitiesPagination/selectedPage']),
      size: Number(rootGetters['landingEntitiesPagination/selectedSize']),
      excludedType: [EntityTypes.CooperativeAssociation],
      ...filtersBy,
    };
    commit('setLoading', true);
    try {
      const entitiesForAdminResp = await EntitiesListApi.fetchFilteredEntities(payload);
      const { entities } = entitiesForAdminResp.data.listEntitiesForAdmin;
      const bulkMembersCountResp = await bulkMembersCount(makeBulkMemberCountPayload(entities));
      let entitiesWithMembersCount = null;
      if (!bulkMembersCountResp.error) {
        entitiesWithMembersCount = bulkMembersCountResp;
      }
      const modifiedEntities = entities.map((entity) => ({
        ...entity,
        membersCount: findMembersCount(entitiesWithMembersCount, entity),
      }));
      const { meta } = entitiesForAdminResp.data.listEntitiesForAdmin;

      commit('setLandingEntities', handlePublicEntities(modifiedEntities));
      commit('landingEntitiesPagination/setTotalPages', meta.pageCount, { root: true });
      commit('landingEntitiesPagination/setTotalRecords', meta.recordsCount, { root: true });
    } catch (e) {
      console.warn(e);
    } finally {
      commit('setLoading', false);
    }
  },
};

export default {
  namespaced: true,
  state: mainState,
  getters: mainGetters,
  mutations: mainMutations,
  actions: mainActions,
};
