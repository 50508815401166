import { GraphQLRequest } from '@/setup/axios';
import {
  FIND_ENTITY_BY_ID_FOR_PROFILE,
  ENTITY_ADDITIONAL_INFO,
  FETCH_ENTITY_CONTACTS,
  FETCH_ENTITY_EMPLOYEES,
} from '@/api/graphql/entity-profile-gql';
import { MembershipEntityTypes } from '@/constants/enums';

class EntityProfileApi {
  findEntityProfileInfoByEntityId = (id) => (
    GraphQLRequest({
      query: FIND_ENTITY_BY_ID_FOR_PROFILE,
      variables: {
        id: Number(id),
      },
    })
  )

  findEntityAdditionalInfo = (entityId) => (
    GraphQLRequest({
      query: ENTITY_ADDITIONAL_INFO,
      variables: {
        entityId: Number(entityId),
      },
    })
  )

  fetchEntityContacts = (id) => (
    GraphQLRequest({
      query: FETCH_ENTITY_CONTACTS,
      variables: {
        entityId: Number(id),
      },
    })
  )

  fetchEntityEmployees = ({
    entityId,
    unitType = MembershipEntityTypes.Entity,
    page = 1,
    size = 100,
  }) => (
    GraphQLRequest({
      query: FETCH_ENTITY_EMPLOYEES,
      variables: {
        entityId: Number(entityId),
        page: Number(page),
        size: Number(size),
        unitType,
        active: true,
      },
    })
  )
}

export default new EntityProfileApi();
