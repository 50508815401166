export const GET_USER_REQUESTS = `query GetUserRequests($unitId: Int, $unitType: UnitType) {
  userRequests(page: 1, size: 100, unitId: $unitId, unitType: $unitType) {
    list: userRequests {
      createdAt
      id
      email
      status
      unitId
      unitType
      memberId
      memberType
      customPosition
      specialized
      financeContributionAmount
      numberOfShares
      membershipId
      governmental
      role
      foundingMember
      familyRelation {
        arTitle
        enTitle
        uid
      }
      delegatorProof {
        entityName
        id
        userRequestId
        nonGovernmentalProof {
        commercialRegistrationNumberProofs {
          attachedAt
          createdAt
          docType
          fileSize
          filename
          mimeType
          path
          uid
          updatedAt
          }
          facilityRecordNumberProofs {
            attachedAt
            createdAt
            docType
            fileSize
            filename
            mimeType
            path
            uid
            updatedAt
          }
          gosiNumberProofs {
            attachedAt
            createdAt
            docType
            fileSize
            filename
            mimeType
            path
            uid
            updatedAt
          }
          nonProfitOrganizationLicenseProofs {
            attachedAt
            createdAt
            docType
            fileSize
            filename
            mimeType
            path
            uid
            updatedAt
          }
          zakatCertificateNumberProofs {
            attachedAt
            createdAt
            docType
            fileSize
            filename
            mimeType
            path
            uid
            updatedAt
          }
          endowmentDeedNumberProofs {
            attachedAt
            createdAt
            docType
            fileSize
            filename
            mimeType
            path
            uid
            updatedAt
          }
          facilityRecordNumber
          gosiNumber
          proofType
          proofValue
          zakatCertificateNumber
        }
        delegatorProofLetters {
          docType
          fileSize
          filename
          mimeType
          path
          uid
          attachedAt
        }
      }
      member {
        absherPhone
        birthDateHijri
        birthplace
        createdAt
        degrees
        educationSpecialization
        educationalLevel
        email
        employmentPlace
        fatherName
        firstName
        gender
        grandfatherName
        id
        lastName
        nationalId
        nidExpireDate
        occupation
        previousExperience
        updatedAt
        nationalAddress {
          additionalNo
          buildingNo
          cityAr
          cityEn
          createdAt
          districtAreaAr
          districtAreaEn
          id
          latitude
          longitude
          streetNameAr
          streetNameEn
          unitNo
          updatedAt
          zipCode
        }
        nationality {
          uid
          arTitle
          enTitle
        }
      }
      position {
        arTitle
        enTitle
        uid
      }
    }
  }
}`;

export const GET_DISCLOSURE_FORM = `query findUserRequest($id: Int!) {
  findUserRequest (id: $id) {
    id
    disclosureForm {
      acrm
      crmo
      crmi
      resi
      oman
      rman
      lknw
      naff
      fffl
      nmbd
    }
  }
}`;

export const FIND_ADMIN_REQUEST = `query findEstablishmentAdminRequest($id: Int!) {
  findEstablishmentAdminRequest(id: $id) {
    actionExpectation
    admin {
      email
    }
    adminRole
    createdAt
    departmentId
    id
    lockRequest {
      accountId
      requestId
      requestType
      status
    }
    message
    requestType
    remainingData {
      remainingType
      remainingValue
    }
    status
    unit {
      ... on Entity {
        type
        status
        nameArabic
        nameEnglish
        id
        foundForSelf
        goals {
          ... on FamilyTrustGoal {
            arTitle
            enTitle
            id
          }
        }
        entityNationalAddress {
          additionalNumber
          buildingNumber
          postCode
          streetName
        }
        region {
          arTitle
          enTitle
        }
        city {
          arTitle
          enTitle
        }
      }
    }
    unitId
    unitType
    updatedAt
  }
}`;

export const GET_OWNER_AS_MEMBER = `query findOwnerAsAMemberUserRequestByEntityId($entityId: Int!) {
  findOwnerAsAMemberUserRequestByEntityId(entityId: $entityId) {
    governmental
    role
    id
  }
}`;

export const FETCH_ESTABLISHMENT_REQUESTS = `query establishmentAdminRequests($page: Int!, $size: Int!, $status: [EstablishmentAdminRequestStatus!], $unitId: Int, $unitTitle: String, $unitType: UnitType, $requestType: [EstablishmentAdminRequestType!], $adminRole: [AccountRole!], $active: Boolean) {
  establishmentAdminRequests(page: $page, size: $size, status: $status, unitId: $unitId, unitTitle: $unitTitle, unitType: $unitType, requestType: $requestType, adminRole: $adminRole, active: $active) {
    establishmentAdminRequests {
    id
    actionExpectation
    unitId
    status
    message
    createdAt
    updatedAt
    unitType
    adminRole
    autoAccepted
    admin {
      id
      email
    }
    lockRequest {
      accountId
      requestId
      requestType
      status
    }
    remainingData {
      expireAt
      remainingType
      remainingValue
      pausedAt
      startDate
    }
    unitId
    unit {
      ... on Entity {
        type
        status
        nameArabic
        id
        nameEnglish
      }
      ... on Branch {
        type
        status
        nameEnglish
        id
        nameArabic
        entity {
          nameArabic
          nameEnglish
        }
      }
    }
  }
  meta {
    page
    size
    pageCount
    recordsCount
    }
  }
}`;

export const ACCEPT_ESTABLISHMENT_REQUEST = `mutation acceptEstablishmentAdminRequest($establishmentAdminRequestAttributes: EstablishmentAdminRequestsAcceptInput!) {
  acceptEstablishmentAdminRequest(establishmentAdminRequestAttributes: $establishmentAdminRequestAttributes) {
    id
    status
  }
}`;

export const REJECT_ESTABLISHMENT_REQUEST = `mutation rejectEstablishmentAdminRequest($establishmentAdminRequestAttributes: EstablishmentAdminRequestsRejectInput!) {
  rejectEstablishmentAdminRequest(establishmentAdminRequestAttributes: $establishmentAdminRequestAttributes) {
    id
    status
  }
}`;

export const RESET_ACTION_EXPECTATION_STATUS = `mutation resetActionExpectationEstablishmentAdminRequest($id: Int!) {
  resetActionExpectationEstablishmentAdminRequest(id: $id) {
    id
  }
}`;

export const MEMBERS_COUNT = `query membersCount($units: [MembersCountInput!]!) {
  membersCount(units: $units) {
    numberOfUnitMembers {
      unitId,
      unitType,
      membersCount
    }
  }
}`;

export const FIND_LAST_REJECTED_ESTABLISHMENT_ADMIN_REQUEST = `query findLastRejectedEstablishmentAdminRequest($unitId: Int!, $unitType: UnitType!) {
  findLastRejectedEstablishmentAdminRequest(unitId: $unitId, unitType: $unitType) {
    message
    unitId
    adminRole
    id
  }
}`;
