import isEmpty from 'lodash.isempty';
import * as entityAPI from '@/api/entity.api';
import { formatDate } from '@/helpers/date';
import { ErrorHandler } from '@/helpers';
import * as lookupAPI from '@/api/lookup.api';
import { deserializer } from '@/helpers/api';
import { getFullName } from '@/services/membership.service';

export const parseMultipleValues = (value, separator = ',') => {
  if (Array.isArray(value)) {
    return value.map((v) => v.toString());
  }
  if (value && typeof value === 'string') {
    return Object.freeze(value.split(separator));
  }
  return undefined;
};

export const parseArrayToString = (arr) => {
  if (!isEmpty(arr)) {
    return arr.join(',');
  }
  return '';
};

export const parseDocuments = (docObj) => {
  try {
    if (docObj) {
      return Object.freeze([JSON.parse(docObj)]);
    }
    return undefined;
  } catch (e) {
    console.warn('Invalid JSON');
    return undefined;
  }
};

const handleOwnerMembership = (owner) => {
  if (owner) {
    return {
      id: owner.id,
      role: owner.role,
      governmental: owner.governmental,
    };
  }
  return null;
};

const normalizeDocumentToPDF = (files) => {
  if (Array.isArray(files) && files.length) {
    return files.map((file) => ({
      url: file.path,
      name: file.filename,
      uid: file?.uid,
    }));
  }
  return null;
};

export const normalizeEntity = (entity = {}) => ({
  type: entity.type,
  becomeMember: entity.autocreateMembershipForOwner || false,
  parentType: entity.parentType,
  id: entity.id,
  status: entity.status,
  avatar: entity?.entityProfile?.logo || null,
  financialCommitment: entity.financialCommitment || false,
  createdAt: formatDate(entity.createdAt),
  updatedAt: entity.updatedAt ? formatDate(entity.updatedAt) : formatDate(entity.createdAt),
  entityEstablisherName: getFullName(entity.owner),
  entityEstablisherNid: entity.owner?.nationalId,
  nameArabic: entity.nameArabic,
  nameEnglish: entity.nameEnglish,
  parentNameArabic: entity.parentNameArabic,
  parentNameEnglish: entity.parentNameEnglish,
  typeOfBenefits: entity.typeOfBenefits,
  classification: entity.classificationDetails?.departmentId,
  firstClassification: entity.classificationDetails?.firstSubClassificationId,
  secondClassification: entity.classificationDetails?.secondSubClassificationId,
  classificationDetails: {
    ...entity.classificationDetails,
  },
  membershipTypeAssociation: entity.membershipType,
  activities: entity.activities,
  goals: entity.goals,
  activityIds: entity.activityIds,
  goalIds: entity.goalIds,
  membershipTypes: parseMultipleValues(entity.membershipTypes),
  ceoEducationalLevelCondition: entity.ceoEducationalLevelCondition || null,
  boardFirstTermLength: entity.boardFirstTermLength,
  boardLaterTermsLength: entity.boardLaterTermsLength,
  workerMembershipFee: entity.workerMembershipFee || entity.membershipFee,
  conditionsWorker: entity.workerConditions,
  membershipDuties: entity.membershipDuties,
  membershipRights: entity.membershipRights,
  associateMembershipFee: entity.associateMembershipFee,
  ceoDegreesCondition: entity.ceoDegreesCondition,
  ceoPreviousExperienceCondition: entity.ceoPreviousExperienceCondition || null,
  ceoCustomCondition: entity.ceoCustomCondition,
  capital: entity.capital,
  numberOfShares: entity.numberOfShares,
  minNumOfSharesPerMember: entity.minNumOfSharesPerMember,
  bankCertificates: entity.bankCertificates,
  bankCertificateOptional: parseDocuments(entity.bankCertificate),
  eligibilityStudy: normalizeDocumentToPDF(entity.eligibilityStudyFiles),
  bank: entity.bank,
  bankId: entity.bank?.id,
  bankAccount: entity.bankAccount,
  department: entity.department,
  bankBranchName: entity.bankBranchName,
  region: entity.regionCode,
  regionObj: entity.region,
  cityObj: entity.city,
  city: entity.cityUid,
  ownerId: entity?.owner?.id,
  step: entity.step || 0,
  numberOfBoardMembers: entity.numberOfBoardMembers,
  shareValue: entity.shareValue,
  copiedFrom: entity.copiedFrom,
  membershipRequirements: parseArrayToString(entity.membershipRequirements),
  tempBankAccount: entity.tempBankAccount || {},
  capitalReductionPercentage: entity.capitalReductionPercentage,
  contributionSources: entity?.contributionSources || [],
  autocreateMembershipForOwner: entity.autocreateMembershipForOwner,
  ownerMembership: handleOwnerMembership(entity.ownerMembership),
  supportingDocuments: normalizeDocumentToPDF(entity.supportingDocuments),
  updatedAtHijri: entity.updatedAtHijri,
  membersCount: entity.membersCount || 0,
  isNama: entity.isNama ?? undefined,
  acceptedAt: formatDate(entity?.acceptedAt),
  foundForSelf: entity.foundForSelf,
  foundFor: entity.foundFor,
  entityLicenseNumber: entity.entityLicenseNumber,
  entityLicenses: normalizeDocumentToPDF(entity.entityLicenses),
  eligibilityStudyFiles: normalizeDocumentToPDF(entity.eligibilityStudyFiles),
  entityPolicies: normalizeDocumentToPDF(entity.entityPolicies),
  establishmentDecisions: normalizeDocumentToPDF(entity.establishmentDecisions),
  migrationDelegateLetters: normalizeDocumentToPDF(entity.migrationDelegateLetters),
});

export const getTotalContributionEntity = async (entityId) => {
  try {
    const { data } = await entityAPI.getTotalContribution(entityId);
    return data?.totalContributions?.totalContributions;
  } catch (e) {
    console.warn(e);
    return ErrorHandler.parseFetchErrors(e);
  }
};

export const getEntityClassifications = async (ids, config = {}) => {
  if (!ids) return [];
  try {
    const res = await lookupAPI.fetchClassificationsDetails(ids, config);
    const data = await deserializer(res.data) || [];
    return data.map((item) => ({
      id: item.id,
      secondClassification: item.secondSubClassificationArTitle,
      firstClassification: item.firstSubClassificationArTitle,
      classification: item.mainClassificationArTitle,
      department: item.departmentArTitle,
      description: item.secondSubClassificationArDescription,
      firstSubClassificationId: item.firstSubClassificationId,
      mainClassificationId: item.mainClassificationId,
    }));
  } catch (e) {
    console.warn(e);
    return [];
  }
};

export const getBanksByEntityId = async (entityId, unitType) => {
  try {
    const res = await entityAPI.fetchBanksListByUnitId(entityId, unitType);
    return res.data.entitiesBanks;
  } catch (e) {
    return ErrorHandler.parseFetchErrors(e);
  }
};

export const listContributionSources = async (entityId) => {
  try {
    const res = await entityAPI.listContributionSources(entityId);
    return res?.data?.listContributionSources?.contributionsSources;
  } catch (e) {
    return ErrorHandler.parseFetchErrors(e);
  }
};

export const findTempBankAccountByEntityId = async (entityId) => {
  try {
    const res = await entityAPI.findTempBankAccountByEntityId(entityId);
    return res?.data?.findTempBankAccountByEntityId;
  } catch (e) {
    return ErrorHandler.parseFetchErrors(e);
  }
};

export const normalizeBranch = (branch = {}) => ({
  nameArabic: branch.nameArabic,
  nameEnglish: branch.nameEnglish,
  goals: branch.goals,
  regionObj: branch.region,
  cityObj: branch.city,
  status: branch.status,
  proofFile: normalizeDocumentToPDF(branch.proofFile) ?? [],
  approvalFile: normalizeDocumentToPDF(branch.approvalFile) ?? [],
});

export const findEntityNew = async (entityId) => {
  try {
    const res = await entityAPI.findEntity(entityId);
    const data = res.data?.findEntityById;
    return {
      ...normalizeEntity(data),
      familyRelation: res.data.findOwnerAsAMemberUserRequestByEntityId?.familyRelation,
    };
  } catch (e) {
    return ErrorHandler.parseFetchErrors(e);
  }
};
