import { getField, updateField } from 'vuex-map-fields';
import EntityProfileApi from '@/api/entity-profile.api';
import ErrorHandler from '@/helpers/errors';
import { EntityTypes } from '@/constants/enums';
import { normalizeEntityLocations } from '@/services/requests.service';

const normalizeEntityContacts = (data = {}) => ({
  contactId: data.id,
  email: data.email || '',
  facebook: data.facebookAccount || '',
  instagram: data.instagramAccount || '',
  linkedin: data.linkedinAccount || '',
  phone: data.phoneNumber || '',
  snapchat: data.snapchatAccount || '',
  telephone: data.telephoneNumber || '',
  twitter: data.twitterAccount || '',
  unifiedNumber: data.unifiedNumber || '',
  website: data.website || '',
});

const normalizeEntityProfile = (data = {}) => ({
  avatar: data?.entityProfile?.logo || null,
  ...data,
  areasOfActivity: normalizeEntityLocations(data?.areasOfActivity),
});

const mainState = {
  entityProfile: normalizeEntityProfile(),
  memberships: [],
  membershipConditions: [],
  entityContacts: normalizeEntityContacts(),
  entityEmployees: [],
  entityEmployeesLoading: false,
};

const mainGetters = {
  getField,
  getEntityProfile: (state) => state.entityProfile,
  getProfileMemberships: (state) => state.memberships,
  getProfileMembershipConditions: (state) => state.membershipConditions,
  getEntityContacts: (state) => state.entityContacts,
  memberRole: (state) => state.entityProfile?.memberRole,
  entityType: (state) => state.entityProfile?.type,
  isPrivateCorporation: (state) => state.entityProfile?.type === EntityTypes.PrivateCorporation,
  isPrivateAssociation: (state) => state.entityProfile?.type === EntityTypes.PrivateAssociation,
  getEntityEmployees: (state) => state.entityEmployees,
  getEntityEmployeesLoading: (state) => state.entityEmployeesLoading,
};

const mainMutations = {
  updateField,
  setEntityProfile: (store, profile) => {
    store.entityProfile = normalizeEntityProfile(profile);
  },
  setEntityProfileMemberships: (store, data) => {
    store.memberships = data;
  },
  setEntityProfileMembershipConditions: (store, data) => {
    store.membershipConditions = data;
  },
  setEntityProfileMembersInfo: (store, id) => {
    store.memberships = store.memberships.map(
      (m) => ({
        ...m,
        showInfo: m.id === id ? !m.showInfo : false,
      }),
    );
    store.errors = [];
  },
  setEntityProfileEmployeesInfo: (store, id) => {
    store.entityEmployees = store.entityEmployees.map(
      (e) => ({
        ...e,
        showInfo: e.id === id ? !e.showInfo : false,
      }),
    );
    store.errors = [];
  },
  setEntityContacts: (store, data) => {
    store.entityContacts = data;
  },
  setEntityEmployees: (store, arr) => {
    store.entityEmployees = arr;
  },
  setEntityEmployeesLoading: (store, val) => {
    store.entityEmployeesLoading = val;
  },
};

const mainActions = {
  fetchEntityProfile: async ({ commit, dispatch }, entityId) => {
    const lazyCalls = [
      'fetchEntityContacts',
      'findEntityAdditionalInfo',
      'fetchEntityEmployees',
    ];
    lazyCalls.forEach((name) => dispatch(name, entityId));
    let result = null;
    try {
      const response = await EntityProfileApi.findEntityProfileInfoByEntityId(entityId);
      result = response?.data?.entityProfile || {};
      commit('setEntityProfile', result);
    } catch (e) {
      result = ErrorHandler.parseFetchErrors(e);
    }
    return result;
  },

  findEntityAdditionalInfo: async ({ commit }, entityId) => {
    let result = null;
    try {
      const response = await EntityProfileApi.findEntityAdditionalInfo(entityId);
      result = response?.data || {};
      commit('setEntityProfileMemberships', result.memberships.memberships);
      commit('setEntityProfileMembershipConditions', result.membershipConditions.membershipConditions);
    } catch (e) {
      result = ErrorHandler.parseFetchErrors(e);
    }
    return result;
  },

  fetchEntityEmployees: async ({ commit }, entityId) => {
    if (!entityId) return null;
    commit('setEntityEmployeesLoading', true);
    let result = null;
    try {
      const res = await EntityProfileApi.fetchEntityEmployees({ entityId });
      result = res.data?.listEmployeeRequests?.employeeRequests;
      commit('setEntityEmployees', result);
    } catch (e) {
      result = 1;
    } finally {
      commit('setEntityEmployeesLoading', false);
    }
    return result;
  },

  fetchEntityContacts: async ({ commit }, entityId) => {
    let result = null;
    try {
      const response = await EntityProfileApi.fetchEntityContacts(entityId);
      result = response?.data?.findEntityContactByEntityId || {};
    } catch (e) {
      result = ErrorHandler.parseFetchErrors(e);
    } finally {
      commit('setEntityContacts', normalizeEntityContacts(result?.error ? {} : result));
    }
    return result;
  },
};

export default {
  namespaced: true,
  state: mainState,
  getters: mainGetters,
  mutations: mainMutations,
  actions: mainActions,
};
