import { required } from 'vee-validate/dist/rules';
import uniqBy from 'lodash.uniqby';
import { ENTITY_BANNED_WORDS } from '@/constants';
import i18n from '@/i18n';

export const RULES = {
  required: { ...required },
  minLengthCustom: {
    params: ['length'],
    validate(value, { length }) {
      value = value.toString();
      return value.length >= Number(length);
    },
  },
  notTrue: {
    validate: (value) => !value,
  },
  validate: {
    validate: (value) => Boolean(value),
  },
  bannedWords: {
    validate: (value) => !ENTITY_BANNED_WORDS.some((w) => value.split(' ').includes(w)),
    message: (_, values) => {
      // eslint-disable-next-line no-underscore-dangle
      const inputs = values._value_.split(' ') || [];
      const bannedWords = ENTITY_BANNED_WORDS.reduce((acc, word) => {
        if (inputs.find((item) => item === word)) {
          acc.push(word);
        }
        return uniqBy(acc);
      }, []);
      return i18n.t('validations.entityBannedWords', { words: bannedWords.join(' ') });
    },
  },
};
