import { GraphQLRequest } from '@/setup/axios';
import { EstablishmentType } from '@/constants/enums';
import { TOTAL_CONTRIBUTION } from '@/api/graphql/total-contribution-gql';
import { GET_OWNER_AS_MEMBER } from '@/api/graphql/user-requests-gql';
import {
  FIND_ENTITY_BY_ID,
  FIND_ESTABLISHMENT_ADMIN_REQUEST_STATUS_BY_ID,
  LIST_CONTRIBUTION_SOURCES,
  FIND_TEMP_BANK_ACCOUNT_BY_ENTITY_ID,
  FIND_BRANCH_BY_ID,
  ENTITY_LOCATIONS,
  UPDATE_ENTITY_BY_SUPERVISORY,
  CREATE_OR_UPDATE_ENTITY_LOCATIONS,
} from '@/api/graphql/entity-gql';
import { LIST_ENTITY_BANKS } from '@/api/graphql/family-fund-gql';

export function getTotalContribution(entityId) {
  return GraphQLRequest({
    query: TOTAL_CONTRIBUTION,
    variables: {
      entityId: Number(entityId),
    },
  });
}

export function findOwnerAsMemberUserRequestByEntityId(entityId) {
  return GraphQLRequest({
    query: GET_OWNER_AS_MEMBER,
    variables: {
      entityId: Number(entityId),
    },
  });
}

export function findEstablishmentAdminRequestStatus(requestId) {
  return GraphQLRequest({
    query: FIND_ESTABLISHMENT_ADMIN_REQUEST_STATUS_BY_ID,
    variables: {
      id: Number(requestId),
    },
  });
}
export function findEntity(entityId) {
  return GraphQLRequest({
    query: FIND_ENTITY_BY_ID,
    variables: {
      id: Number(entityId),
    },
  });
}

export function updateEntityBySupervisoryAdmin({
  id,
  nameArabic,
  nameEnglish,
  goalIds,
  activityIds,
}) {
  return GraphQLRequest({
    query: UPDATE_ENTITY_BY_SUPERVISORY,
    variables: {
      id,
      nameArabic,
      nameEnglish,
      goalIds,
      activityIds,
    },
  });
}

export function fetchEntityLocationsByEntityId({ id, page = 1, size = 10 }) {
  return GraphQLRequest({
    query: ENTITY_LOCATIONS,
    variables: {
      page,
      size,
      entityId: Number(id),
    },
  });
}

export function listContributionSources(entityId, page = 1, size = 10) {
  return GraphQLRequest({
    query: LIST_CONTRIBUTION_SOURCES,
    variables: {
      entityId: Number(entityId),
      page,
      size,
    },
  });
}

export function findTempBankAccountByEntityId(entityId) {
  return GraphQLRequest({
    query: FIND_TEMP_BANK_ACCOUNT_BY_ENTITY_ID,
    variables: {
      entityId: Number(entityId),
    },
  });
}

export function findBranchById(id) {
  return GraphQLRequest({
    query: FIND_BRANCH_BY_ID,
    variables: {
      id: Number(id),
    },
  });
}

export const fetchBanksListByUnitId = (unitId, unitType = EstablishmentType.Entity) => (
  GraphQLRequest({
    query: LIST_ENTITY_BANKS,
    variables: {
      unitId: Number(unitId),
      unitType: String(unitType),
    },
  })
);

export const createOrUpdateEntityLocations = (entityId, entityLocations) => GraphQLRequest({
  query: CREATE_OR_UPDATE_ENTITY_LOCATIONS,
  variables: {
    entityLocationsCreateOrUpdateAttributes: {
      entityId: Number(entityId),
      entityLocations,
    },
  },
});

class EntityApi {
  findTempBankAccountByEntityId = (entityId) => GraphQLRequest({
    query: FIND_TEMP_BANK_ACCOUNT_BY_ENTITY_ID,
    variables: {
      entityId: Number(entityId),
    },
  })

  listContributionSources = (entityId, page = 1, size = 10) => GraphQLRequest({
    query: LIST_CONTRIBUTION_SOURCES,
    variables: {
      entityId: Number(entityId),
      page,
      size,
    },
  })
}

export default new EntityApi();
