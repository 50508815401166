<template>
  <aside class="sidebar">
    <div class="sidebar-wrap">
      <mq-layout mq="mobile-">
        <Header ref="headerComponent">
          <user-menu
            slot="header-right"
            :show-go-to-profile="true"
          />
          <sidebar-list-item
            v-for="(item, index) in availableSidebarItems"
            slot="mobile-sidebar-items"
            :key="index"
            :name="item.name"
            :url="item.routeTo"
            :dropdown-name="item.dropdownName"
            :dropdown-items="item.dropdownItems"
            :is-collapsed="item.isCollapsed"
            :external-url="item.externalUrl"
            view-box="0 0 32 32"
            @clickLink="clickMobileLink"
          >
            <slot>
              <component
                :is="item.icon"
                :item="item.icon"
              />
            </slot>
          </sidebar-list-item>
        </Header>
      </mq-layout>
      <mq-layout mq="tablet+">
        <sidebar-header />
      </mq-layout>
      <sidebar-content>
        <ul
          v-if="showList"
          class="sidebar-list reset-list"
        >
          <sidebar-list-item
            v-for="(item, index) in availableSidebarItems"
            :key="index"
            :name="item.name"
            :url="item.routeTo"
            :pages="item.pages"
            :count="item.count"
            :dropdown-name="item.dropdownName"
            :dropdown-items="item.dropdownItems"
            :is-collapsed="item.isCollapsed"
            :external-url="item.externalUrl"
            view-box="0 0 32 32"
          >
            <slot>
              <component
                :is="item.icon"
                :item="item.icon"
              />
            </slot>
          </sidebar-list-item>
        </ul>
      </sidebar-content>
      <Footer v-if="showList" />
    </div>
  </aside>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import SidebarHeader from '@/containers/sidebar-header/SidebarHeader.vue';
import SidebarContent from '@/components/sidebar-content/SidebarContent.vue';
import Header from '@/containers/header/Header.vue';
import Footer from '@/containers/footer/Footer.vue';
import UserMenu from '@/containers/user-menu/UserMenu.vue';
import SidebarListItem from '@/containers/sidebar/sidebar-list-item/SidebarListItem.vue';
import { AdminRoles } from '@/constants/enums';
import {
  BENEFICIARY_SPA,
  FINANCIAL_SPA,
  INSPECTION_VISIT_SPA,
  MANAGE_MEETINGS_SPA,
} from '@/constants';
import { isFeatureAvailable } from '@/helpers/features-config';

export default {
  name: 'Sidebar',
  components: {
    Header,
    Footer,
    UserMenu,
    SidebarContent,
    SidebarHeader,
    SidebarListItem,
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'auth/isAuthenticated',
      loading: 'auth/loading',
      adminRole: 'auth/adminRole',
      objectionsCount: 'objectionRequests/objectionsCount',
    }),
    sidebarItems() {
      return [
        {
          name: 'menu.searchEstablishedEntities',
          icon: 'IconSearch',
          routeTo: {
            name: 'EntitiesList',
          },
          available: [
            AdminRoles.SuperAdmin,
            AdminRoles.RegistrationAndPermitAdmin,
            AdminRoles.RegistrationAndPermitManager,
            AdminRoles.RegistrationAndPermitEmployee,
            AdminRoles.GovernanceAdmin,
          ].includes(this.adminRole),
        },
        {
          name: 'menu.receivedEntityRequests',
          icon: 'IconAsk',
          routeTo: {
            name: 'EntityRequests',
          },
          available: [
            AdminRoles.Supervisory,
            AdminRoles.Specialized,
            AdminRoles.HrsdAdmin,
            AdminRoles.HrsdEmployee,
            AdminRoles.RegistrationAndPermitAdmin,
            AdminRoles.RegistrationAndPermitManager,
            AdminRoles.RegistrationAndPermitEmployee,
            AdminRoles.RegistrationAndPermitCeo,
          ].includes(this.adminRole),
        },
        {
          name: 'menu.branchEstablishmentRequests',
          icon: 'IconReceivedBranchRequest',
          routeTo: {
            name: 'BranchRequests',
          },
          available: [
            AdminRoles.GovernanceCenterEmployee,
            AdminRoles.GovernanceAdmin,
            AdminRoles.Supervisory,
          ].includes(this.adminRole),
        },
        {
          name: 'menu.objections',
          icon: 'IconAsk',
          routeTo: {
            name: 'ObjectionRequests',
          },
          count: this.objectionsCount > 99 ? '99+' : this.objectionsCount,
          available: [
            AdminRoles.RegistrationAndPermitEmployee,
            AdminRoles.RegistrationAndPermitAdmin,
          ].includes(this.adminRole),
        },
        {
          name: 'menu.eshopRequests',
          icon: 'IconEshopRequests',
          routeTo: {
            name: 'EshopRequests',
          },
          available: [
            AdminRoles.RegistrationAndPermitAdmin,
            AdminRoles.RegistrationAndPermitEmployee,
          ]
            .includes(this.adminRole),
        },
        {
          name: 'menu.employeeRequests',
          icon: 'IconEmployeeRequest',
          routeTo: {
            name: 'EmployeeRequests',
          },
          available: [
            AdminRoles.Specialized,
            AdminRoles.HrsdEmployee,
            AdminRoles.GovernanceAdmin,
          ]
            .includes(this.adminRole),
        },
        {
          name: 'menu.bankDelegationsRequest',
          icon: 'IconAppointingBankDelegations',
          routeTo: {
            name: 'BankRequests',
          },
          available: [
            AdminRoles.Specialized,
          ]
            .includes(this.adminRole),
        },
        {
          name: 'menu.bankDelegationRequests',
          icon: 'IconAppointingBankDelegations',
          routeTo: {
            name: 'BankDelegationRequests',
          },
          available: [
            AdminRoles.GovernanceCenterEmployee,
          ].includes(this.adminRole),
        },
        {
          name: 'menu.policyRequests',
          icon: 'IconEditRequest',
          routeTo: {
            name: 'EditPolicyRequests',
          },
          available: [
            AdminRoles.Supervisory,
            AdminRoles.GovernanceAdmin,
          ]
            .includes(this.adminRole),
        },
        {
          name: 'menu.auditOfficer',
          icon: 'IconAuditOfficer',
          routeTo: {
            name: 'AdminList',
            params: {
              adminType: 'audit-officer',
            },
          },
          available: [
            AdminRoles.SuperAdmin,
          ]
            .includes(this.adminRole),
        },
        {
          name: 'menu.supervisoryEntityAdmins',
          icon: 'IconSupervisory',
          routeTo: {
            name: 'AdminList',
            params: {
              adminType: 'supervisory',
            },
          },
          available: [
            AdminRoles.SuperAdmin,
          ]
            .includes(this.adminRole),
        },
        {
          name: 'menu.specializedEntityAdmins',
          icon: 'IconSpecialized',
          routeTo: {
            name: 'AdminList',
            params: {
              adminType: 'specialized',
            },
          },
          available: [
            AdminRoles.SuperAdmin,
          ]
            .includes(this.adminRole),
        },
        {
          name: 'menu.centerAdmins',
          icon: 'IconCenterAdmin',
          routeTo: {
            name: 'AdminList',
            params: {
              adminType: 'registration-and-permit-admin',
            },
          },
          available: [
            AdminRoles.SuperAdmin,
          ]
            .includes(this.adminRole),
        },
        {
          name: 'menu.ManagerAdmins',
          icon: 'IconCenterAdmin',
          routeTo: {
            name: 'AdminList',
            params: {
              adminType: 'registration_and_permit_manager',
            },
          },
          available: [
            AdminRoles.SuperAdmin,
          ]
            .includes(this.adminRole),
        },
        {
          name: 'menu.registrationAndPermitEmployee',
          icon: 'IconCenterEmployeeAdmin',
          routeTo: {
            name: 'AdminList',
            params: {
              adminType: 'registration-and-permit-employee',
            },
          },
          available: [
            AdminRoles.SuperAdmin,
          ]
            .includes(this.adminRole),
        },
        {
          name: 'menu.registrationAndPermitCeo',
          icon: 'IconCeoAdmin',
          routeTo: {
            name: 'AdminList',
            params: {
              adminType: 'registration-and-permit-ceo',
            },
          },
          available: [
            AdminRoles.SuperAdmin,
          ]
            .includes(this.adminRole),
        },
        {
          name: 'menu.hrsdAdmins',
          icon: 'IconHrsdAdmin',
          routeTo: {
            name: 'AdminList',
            params: {
              adminType: 'hrsd-admin',
            },
          },
          available: [
            AdminRoles.SuperAdmin,
          ]
            .includes(this.adminRole),
        },
        {
          name: 'menu.hrsdEmployees',
          icon: 'IconHrsdEmployee',
          routeTo: {
            name: 'AdminList',
            params: {
              adminType: 'hrsd-employee',
            },
          },
          available: [
            AdminRoles.SuperAdmin,
          ]
            .includes(this.adminRole),
        },
        {
          name: 'menu.governanceAdmin',
          icon: 'IconGovernanceAdmin',
          routeTo: {
            name: 'AdminList',
            params: {
              adminType: 'governance-admin',
            },
          },
          available: [
            AdminRoles.SuperAdmin,
          ]
            .includes(this.adminRole),
        },
        {
          name: 'menu.governanceHrsdEmployee',
          icon: 'IconGovernanceHrsdEmployee',
          routeTo: {
            name: 'AdminList',
            params: {
              adminType: 'governance-hrsd-employee',
            },
          },
          available: [
            AdminRoles.SuperAdmin,
          ]
            .includes(this.adminRole),
        },
        {
          name: 'menu.governanceCenterEmployee',
          icon: 'IconGovernanceCenterEmployee',
          routeTo: {
            name: 'AdminList',
            params: {
              adminType: 'governance-center-employee',
            },
          },
          available: [
            AdminRoles.SuperAdmin,
          ]
            .includes(this.adminRole),
        },
        {
          name: 'menu.beneficiaryAdmin',
          icon: 'IconBeneficiaryAdmin',
          routeTo: {
            name: 'AdminList',
            params: {
              adminType: 'beneficiary-admin',
            },
          },
          available: [
            AdminRoles.SuperAdmin,
          ]
            .includes(this.adminRole),
        },
        {
          name: 'menu.centerFinancialSafetyEmployee',
          icon: 'IconCenterFinancialSafetyEmployee',
          externalUrl: `${this.financialSpa}/admin-list/center-financial-safety-employee`,
          available: [
            AdminRoles.SuperAdmin,
          ]
            .includes(this.adminRole),
        },
        {
          name: 'menu.charteredAccountantOfficeAdmin',
          icon: 'IconCharteredOfficeAdmin',
          externalUrl: `${this.financialSpa}/admin-list/chartered-accountant-office-admin`,
          available: [
            AdminRoles.SuperAdmin,
          ].includes(this.adminRole),
        },
        {
          name: 'menu.references',
          icon: 'IconReferences',
          routeTo: {
            name: 'References',
          },
          available: [
            AdminRoles.Specialized,
          ].includes(this.adminRole)
            && isFeatureAvailable(this.$enums.featureNames.referencesRoute),
        },
        {
          name: 'menu.temporaryBankAccountRequests',
          icon: 'IconBankAccountRequests',
          routeTo: {
            name: 'TemporaryBankAccountRequests',
          },
          available: [
            AdminRoles.HrsdEmployee,
          ].includes(this.adminRole),
        },
        {
          name: 'menu.entityActivitiesRequests',
          icon: 'IconEntityActivitiesRequests',
          available: [
            AdminRoles.Supervisory,
            AdminRoles.HrsdEmployee,
            AdminRoles.GovernanceAdmin,
            AdminRoles.GovernanceCenterEmployee,
          ].includes(this.adminRole),
          isCollapsed: this.isEntityActivitiesRequestsTabActive,
          dropdownName: 'EntityActivityDropdown',
          dropdownItems: [
            {
              name: 'menu.receivedEntityActivitiesRequests',
              available: [
                AdminRoles.Supervisory,
                AdminRoles.GovernanceAdmin,
                AdminRoles.HrsdEmployee,
                AdminRoles.GovernanceCenterEmployee,
              ].includes(this.adminRole),
              routeTo: {
                name: 'NewEntityActivityRequests',
              },
            },
            {
              name: 'menu.acceptedEntityActivitiesRequests',
              available: [
                AdminRoles.Supervisory,
                AdminRoles.GovernanceAdmin,
              ].includes(this.adminRole),
              routeTo: {
                name: 'EntityActivityAcceptedRequests',
              },
            },
          ],
        },
        {
          name: 'menu.charteredAccountantOfficesManagement',
          icon: 'IconCharteredAccountantOfficesManagement',
          externalUrl: `${this.financialSpa}/financial-account-offices-list`,
          available: [
            AdminRoles.CenterFinancialSafetyEmployee,
          ].includes(this.adminRole),
        },
        {
          name: 'menu.officeAdminEntitiesList',
          icon: 'IconList',
          externalUrl: `${this.financialSpa}/contracting-entities-admin`,
          available: [
            AdminRoles.CharteredAccountantOfficeAdmin,
          ].includes(this.adminRole),
        },
        {
          name: 'menu.beneficiaryAdminDashboard',
          icon: 'IconDelegateBeneficiaryRequest',
          externalUrl: this.beneficiarySpa,
          available: [
            AdminRoles.BeneficiaryAdmin,
          ].includes(this.adminRole),
        },
        {
          name: 'menu.inspectionVisit',
          icon: 'IconInspectionVisit',
          externalUrl: this.inspectionVisitSpa,
          available: [
            AdminRoles.SuperAdmin,
            AdminRoles.Supervisory,
            AdminRoles.HrsdAdmin,
            AdminRoles.HrsdEmployee,
            AdminRoles.RegistrationAndPermitAdmin,
            AdminRoles.RegistrationAndPermitEmployee,
          ].includes(this.adminRole),
        },
        {
          name: 'menu.updatingEntitiesRequests',
          icon: 'IconAsk',
          routeTo: {
            name: 'UpdatingEntitiesRequests',
          },
          available: [
            AdminRoles.AuditOfficer,
          ].includes(this.adminRole),
        },
        {
          name: 'menu.meetings',
          icon: 'IconCalendar',
          available: [
            AdminRoles.Supervisory,
            AdminRoles.GovernanceCenterEmployee,
            AdminRoles.GovernanceHrsdEmployee,
          ]
            .includes(this.adminRole)
            && isFeatureAvailable(this.$enums.featureNames.manageMeetings),
          dropdownName: '',
          dropdownItems: [
            {
              name: 'menu.manageMeetings',
              available: [
                AdminRoles.GovernanceCenterEmployee,
                AdminRoles.GovernanceHrsdEmployee,
              ].includes(this.adminRole),
              externalUrl: this.manageMeetingsSpa,
            },
            {
              name: 'menu.generalAssociationMeetings',
              externalUrl: this.generalAssociationMeetingsSpa,
              available: [
                AdminRoles.GovernanceCenterEmployee,
                AdminRoles.GovernanceHrsdEmployee,
                AdminRoles.Supervisory,
              ].includes(this.adminRole),
            },
            {
              name: 'menu.entitiesMeetings',
              externalUrl: this.entitiesMeetingsSpa,
              available: [
                AdminRoles.GovernanceCenterEmployee,
                AdminRoles.GovernanceHrsdEmployee,
                AdminRoles.Supervisory,
              ].includes(this.adminRole),
            },
          ],
        },
      ];
    },
    isEntityRequestsTabActive() {
      return !!this.$route.path.match(/entity-requests|branch-requests/);
    },
    availableSidebarItems() {
      return this.sidebarItems
        .filter((item) => item.available)
        .map((item) => ({
          ...item,
          dropdownItems: item.dropdownItems?.filter((d) => d.available) || [],
        }));
    },
    showList() {
      return !this.$screen['mobile-'];
    },
    financialSpa() {
      return `${FINANCIAL_SPA}/${this.$i18n.locale}`;
    },
    beneficiarySpa() {
      return `${BENEFICIARY_SPA}/${this.$i18n.locale}`;
    },
    inspectionVisitSpa() {
      return `${INSPECTION_VISIT_SPA}/${this.$i18n.locale}`;
    },
    manageMeetingsSpa() {
      return `${MANAGE_MEETINGS_SPA}/${this.$i18n.locale}/meetings`;
    },
    generalAssociationMeetingsSpa() {
      return `${MANAGE_MEETINGS_SPA}/${this.$i18n.locale}/approval-requests`;
    },
    entitiesMeetingsSpa() {
      return `${MANAGE_MEETINGS_SPA}/${this.$i18n.locale}/entities`;
    },
    entitiesList() {
      return `${MANAGE_MEETINGS_SPA}/${this.$i18n.locale}/entities-list`;
    },
    isEntityActivitiesRequestsTabActive() {
      return !!this.$route.path.match('/entity-activities-requests/');
    },
  },
  created() {
    this.getObjectionsCount();
  },
  methods: {
    ...mapActions({
      getObjectionsCount: 'objectionRequests/getObjectionsCount',
    }),
    clickMobileLink() {
      this.$refs.headerComponent.handleLinkClick();
    },
  },

};
</script>

<style src="./styles.scss" lang="scss" />
